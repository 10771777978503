<template>
    <div class="chart-container">
        <line-chart v-if="loaded" :data="data" :options="options"></line-chart>
    </div>
</template>
  
  <script>

import { Line } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
);

export default {
    components: {
        LineChart: Line,
    },
    props: {
        statusData: Array,
        loaded: Boolean,
    },
    computed: {
        data() {
            return this.chartData;
        },
        options() {
            return this.chartOptions;
        },
    },
    watch: {
        statusData() {
            const data = this.statusData;
            this.chartData.labels = data.map((entry) => entry.timestamp);
            this.chartData.datasets[0].data = data.map((entry) =>
                entry.status === "UP" ? 1 : 0
            );
            // Color is green if all are up and red if any are down
            this.chartData.datasets[0].backgroundColor = data.every(
                (entry) => entry.status === "UP"
            )
                ? "#008000"
                : "#FF0000";
        },
    },
    data() {
        return {
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: "Status",
                        backgroundColor: "#008000",
                        data: [],
                    },
                ],
            },
            chartOptions: {
                responsive: true,
            },
        };
    },
};
</script>

<style scoped>
.chart-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px;
    max-height: calc(min(75vh, 500px));
}
</style>
  