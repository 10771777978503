<template>
    <div class="current-status">
        <div
            :class="{
                lamp: true,
                grey: !loaded,
                green: loaded && latestStatus === 'UP',
                red: loaded && latestStatus !== 'UP',
            }"
        ></div>
        <span>WhereRU is currently {{ latestTime ? ("(" + latestTime.split(", ")[1] + ")") : "" }}: {{ latestStatus }} {{ latestStatus == 'DOWN' ? '- Sad :(' : '' }}</span>
    </div>
</template>
  
  <script>
export default {
    props: {
        statusData: Array,
        loaded: Boolean,
    },
    watch: {
        statusData() {
            const data = this.statusData;
            if (data.length > 0) {
                this.latestStatus = data[data.length - 1].status;
                this.latestTime = data[data.length - 1].timestamp;
            }
        },
    },
    data() {
        return {
            latestStatus: "LOADING...",
            latestTime: "",
        };
    },
};
</script>
  
<style>
.current-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.current-status span {
    margin-left: 10px;
}

.lamp {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: grey;
}
.green {
    background-color: green;
}
.red {
    background-color: red;
}

.grey {
    background-color: gray;
}
</style>
  