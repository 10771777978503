<template>
    <div id="app">
        <div class="logo-container" @click="openWhereRU" style="cursor: pointer;">
            <img class="logo" alt="Vue logo" src="./assets/logoLarge.png" />
            <span>WhereRU Status</span>
        </div>
        <CurrentStatus :loaded="loaded" :statusData="statusData" />
        <HistoricalStatus :loaded="loaded" :statusData="statusData" />
    </div>
</template>

<script>
import axios from "axios";

import CurrentStatus from "./components/CurrentStatus.vue";
import HistoricalStatus from "./components/HistoricalStatus.vue";

export default {
    name: "App",
    components: {
        CurrentStatus,
        HistoricalStatus,
    },
    data() {
        return {
            loaded: false,
            statusData: [],
        };
    },
    methods: {
        async fetchHistoricalData() {
            const response = await axios.get(
                "https://status.where-ru.com/status"
            );
            this.statusData = response.data.reverse().map((entry) => {
                return {
                    timestamp: this.toLocalTime(entry.timestamp),
                    status: entry.status,
                    id: entry.id,
                }
            });
            //this.statusData[this.statusData.length - 1].status = "DOWN";
            this.loaded = true;
        },
        toLocalTime(timestamp) {
            const date = new Date(timestamp + ' UTC');
            return date.toLocaleString();
        },
        openWhereRU() {
            window.open("https://where-ru.com", "_blank");
        }
    },
    created() {
        this.fetchHistoricalData();
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.logo {
    height: 100px;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.logo-container span {
    margin-left: 10px;
    font-size: 50px;
}
</style>
